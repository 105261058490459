import { Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit {
  title = 'tjx-iado-web';

  ngOnInit(): void {
    // window.addEventListener('hide.bs.modal', () => {
    //   var backdrops = document.getElementsByClassName('modal-backdrop')
    //   for (let i = 1; i < backdrops.length; i++) {
    //     backdrops[i].remove();
    //   }
    // });
  }
}
