import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment.local';
import { GetProcessRelationshipQuery } from '../requests/get-process-relationship-query';
import { GetProcessRelationshipResponse } from '../responses/get-process-relationship-response';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RelationshipService {

  private _baseUrl: string = environment.bluePrismUrl;

  constructor(protected client: HttpClient) { }

  public getProcessRelationships(request: GetProcessRelationshipQuery): Observable<GetProcessRelationshipResponse> {
    return this.client.get<GetProcessRelationshipResponse>(`${this._baseUrl}/relationship/process/${request.processId}`);
  }
}
