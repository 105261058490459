<div class="row section">
    <div class="col-2">
      <p>Relationships</p>
    </div>
    <div class="col">
      <div #relationshipContainer></div>
    </div>
    <!-- <ngx-spinner [name]="spinnerName" bdColor="rgba(0, 0, 0, 0.25)" size="medium" color="#fff" type="ball-circus"
      [fullScreen]="false">
      <p style="color: white"> Loading... </p>
    </ngx-spinner> -->
  </div>