import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { PageHeaderComponent } from "../../components/page-header/page-header.component";
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faCircleQuestion } from '@fortawesome/free-regular-svg-icons';
import { Modal, Tooltip } from 'bootstrap';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgFor, NgIf } from '@angular/common';
import { NgxSpinnerModule, NgxSpinnerService } from 'ngx-spinner';
import { SearchService } from '../../services/search.service';
import { debounceTime, Observer } from 'rxjs';
import { SearchRequest } from '../../requests/search-request';
import { SearchResult } from '../../responses/search-response';
import { AutomationService } from '../../services/automation.service';
import { CreateAutomationCommand } from '../../requests/create-automation-command';

@Component({
  selector: 'app-dashboard',
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    PageHeaderComponent,
    FontAwesomeModule,
    ReactiveFormsModule,
    NgxSpinnerModule
  ],
  templateUrl: './dashboard.page.html',
  styleUrl: './dashboard.page.scss'
})
export class DashboardPage implements AfterViewInit {

  @ViewChild('createAutomationHelp') createAutomationHelp!: ElementRef;
  public createAutomationHelpText = 'Please create a new <span class="fw-bold text-automation">Automation Fact Sheet</span> to start using IA DevOps for your development.';

  @ViewChild('searchHelp') searchHelp!: ElementRef;
  public searchHelpText = 'Enter a search term to find the <span class="fw-bold text-release">Project</span>.';

  @ViewChild('projectHelp') projectHelp!: ElementRef;
  public projectHelpText = 'Select the <span class="fw-bold text-release">Project</span> for which you wnant to create an <span class="fw-bold text-automation">Automation Fact Sheet</span>.';

  @ViewChild('detailsHelp') detailsHelp!: ElementRef;
  public detailsHelpText = 'Please enter a Name and Description for your <span class="fw-bold text-automation">Automation Fact Sheet</span>.';

  @ViewChild('automationModal') automationModal!: ElementRef;

  public faCircleQuestion = faCircleQuestion;
  public form: FormGroup = new FormGroup({});
  public noContentResult: boolean = false;
  public searchResultSpinner: string = 'searchResultSpinner';
  public searchResults: SearchResult[] = [];

  get search() { return this.form.get('search') as FormControl; }
  get projectId() { return this.form.get('projectId') as FormControl; }
  get name() { return this.form.get('name') as FormControl; }
  get description() { return this.form.get('description') as FormControl; }

  private _modal!: Modal;

  constructor(
    private _searchSvc: SearchService,
    private _spinnerSvc: NgxSpinnerService,
    private _automationSvc: AutomationService
  ) {
    this.form = new FormGroup({
      search: new FormControl(''),
      projectId: new FormControl({ value: '', disabled: true }, [Validators.required]),
      name: new FormControl({ value: '', disabled: true }, [Validators.required]),
      description: new FormControl({ value: '', disabled: true })
    });
  }

  public ngAfterViewInit(): void {
    this.initTooltips();
    this.initSearch();
    this.initModal();
  }

  public onSubmit(): void {
    if (this.form.invalid) return;
    console.log(this.projectId.value);

    var request = {
      projectId: this.projectId.value,
      name: this.name.value,
      description: this.description.value
    } as CreateAutomationCommand;

    this._automationSvc.createAutomation(request).subscribe({
      next: response => {
        console.log(response);
        this._modal?.hide();
      }
    });
  }

  private initModal(): void {
    this._modal = new Modal(this.automationModal.nativeElement);

    this.automationModal.nativeElement.addEventListener('hidden.bs.modal', () => {
      console.log('Modal hidden');
      this.form.reset();
      this.searchResults = [];
      this.projectId?.disable();
      this.name?.disable();
      this.description?.disable();
      var backdrops = document.getElementsByClassName('modal-backdrop');
      for (var i = 0; i < backdrops.length; i++) {
        backdrops[i].remove();
      }
    });
  }

  private initTooltips(): void {
    var createAutomationTooltip = Tooltip.getOrCreateInstance(this.createAutomationHelp.nativeElement);
    createAutomationTooltip?.setContent({ '.tooltip-inner': this.createAutomationHelpText });

    var searchTooltip = Tooltip.getOrCreateInstance(this.searchHelp.nativeElement);
    searchTooltip?.setContent({ '.tooltip-inner': this.searchHelpText });

    var projectTooltip = Tooltip.getOrCreateInstance(this.projectHelp.nativeElement);
    projectTooltip?.setContent({ '.tooltip-inner': this.projectHelpText });

    var detailsTooltip = Tooltip.getOrCreateInstance(this.detailsHelp.nativeElement);
    detailsTooltip?.setContent({ '.tooltip-inner': this.detailsHelpText });
  }

  private initSearch(): void {
    this.search?.valueChanges
      .pipe(debounceTime(500))
      .subscribe(this.searchValueChanges());

    this.projectId?.valueChanges
      .subscribe(this.projectValueChanges());
  }

  private searchValueChanges(): Partial<Observer<any>> {
    return {
      next: value => {
        if (value === null || value === '') return;
        if (value.length < 3) return;
        this._spinnerSvc.show(this.searchResultSpinner);
        this.onSearch(value);
      }
    }
  }

  private onSearch(value: string): void {
    var request = { phrase: value } as SearchRequest;
    this._searchSvc.searchProjects(request).subscribe({
      next: response => {
        if (response === null || response.results === null || response.results.length === 0) {
          this.noContentResult = true;
          this._spinnerSvc.hide(this.searchResultSpinner);
          return;
        }
        this.searchResults = response.results;
        this.projectId.enable();
        this._spinnerSvc.hide(this.searchResultSpinner);
      }
    });
  }

  private projectValueChanges(): Partial<Observer<any>> {
    return {
      next: value => {
        if (value === null || value === '') return;
        this.name.enable();
        this.description.enable();
      }
    }
  }
}
