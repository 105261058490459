import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GraphService } from '../../services/graph.service';
import { catchError, Observer, of, Subject, switchMap, throwError } from 'rxjs';
import { RelationshipService } from '../../services/relationship.service';
import { GetProcessRelationshipResponse } from '../../responses/get-process-relationship-response';

@Component({
  selector: 'app-relationship-view',
  standalone: true,
  imports: [],
  templateUrl: './relationship-view.component.html',
  styleUrl: './relationship-view.component.scss'
})
export class RelationshipViewComponent implements OnInit {

  @ViewChild('relationshipContainer', { static: true }) relationshipContainer!: ElementRef;

  private _relationships$ = new Subject<GetProcessRelationshipResponse>();
  private _relationships!: GetProcessRelationshipResponse;
  private _svg!: SVGElement;
  private _itemArray: Array<SVGElement> = new Array<SVGElement>();

  constructor(
    private _relationshipSvc: RelationshipService,
    private _route: ActivatedRoute,
    private _graphSvc: GraphService
  ) { }

  ngOnInit(): void {
    this._route.paramMap
      .pipe(switchMap((params) => { return of(params.get('id') as string); }))
      .pipe(switchMap((processId) => { return this._relationshipSvc.getProcessRelationships({ processId }) }))
      // .pipe(finalize(() => { this._spinnerSvc.hide(this.spinnerName); }))
      .pipe(catchError((err) => { return throwError(() => new Error(err)); }))
      .subscribe(this.getProcessRelationshipsObserver());

    this._relationships$
      .subscribe(this.createSvg());
  }

  private getProcessRelationshipsObserver(): Observer<GetProcessRelationshipResponse> {
    return {
      next: (relationships) => {
        this._relationships$.next(relationships);
        // this._spinnerSvc.hide(this.spinnerName);
      }
    } as Observer<GetProcessRelationshipResponse>;
  }

  private createSvg(): Observer<GetProcessRelationshipResponse> {
    return {
      next: (relationships) => {
        this._relationships = relationships;

        this._svg = this._graphSvc.createSvg('100%', '200px');
        this._graphSvc.addItem(this._svg, `${relationships.processName}`, 'P', 10, 10);

        var yOffset = 10;

        var objectCount = Object.keys(relationships.objects).length;
        var objectLabel = objectCount == 1 ? 'Object' : 'Objects';
        if (objectCount > 0) {
          var el = this._graphSvc.addGroup(this._svg, `${objectCount} ${objectLabel}`, 'O', 275, yOffset);
          el.getElementsByTagNameNS('http://www.w3.org/2000/svg', 'a')[0].addEventListener('click', () => this.addGroupItems(this, 'O'));
          this._graphSvc.addPath(this._svg, { x: 240, y: 20 }, { x: 275, y: yOffset + 10 });
          yOffset += 30;
        }

        var processCount = Object.keys(relationships.processes).length;
        var processLabel = processCount == 1 ? 'Process' : 'Processes';
        if (processCount > 0) {
          var el = this._graphSvc.addGroup(this._svg, `${processCount} ${processLabel}`, 'P', 275, yOffset);
          el.getElementsByTagNameNS('http://www.w3.org/2000/svg', 'a')[0].addEventListener('click', () => this.addGroupItems(this, 'P'));
          this._graphSvc.addPath(this._svg, { x: 240, y: 20 }, { x: 275, y: yOffset + 10 });
          yOffset += 30;
        }

        var envvarCount = Object.keys(relationships.environmentVariables).length;
        var envvarLabel = envvarCount == 1 ? 'Environment Variable' : 'Environment Variables';
        if (envvarCount > 0) {
          var el = this._graphSvc.addGroup(this._svg, `${envvarCount} ${envvarLabel}`, 'E', 275, yOffset);
          el.getElementsByTagNameNS('http://www.w3.org/2000/svg', 'a')[0].addEventListener('click', () => this.addGroupItems(this, 'E'));
          this._graphSvc.addPath(this._svg, { x: 240, y: 20 }, { x: 275, y: yOffset + 10 });
          yOffset += 30;
        }

        // var credentialCount = Object.keys(relationships.credentials).length;
        // var credentialLabel = credentialCount == 1 ? 'Credential' : 'Credentials';
        // if (credentialCount > 0) {
        //   this._graphSvc.addGroup(root, `${credentialCount} ${credentialLabel}`, 'C', 275, yOffset);
        //   yOffset += 30;
        // }

        var releaseCount = Object.keys(relationships.releases).length;
        var releaseLabel = releaseCount == 1 ? 'Release' : 'Releases';
        if (releaseCount > 0) {
          var el = this._graphSvc.addGroup(this._svg, `${releaseCount} ${releaseLabel}`, 'R', 275, yOffset);
          el.getElementsByTagNameNS('http://www.w3.org/2000/svg', 'a')[0].addEventListener('click', () => this.addGroupItems(this, 'R'));
          this._graphSvc.addPath(this._svg, { x: 240, y: 20 }, { x: 275, y: yOffset + 10 });
          yOffset += 30;
        }


        this.relationshipContainer.nativeElement.appendChild(this._svg);

        console.log(relationships);
      }
    } as Observer<GetProcessRelationshipResponse>;
  }

  public addGroupItems(context: RelationshipViewComponent, type: 'C' | 'E' | 'O' | 'P' | 'R'): void {
    this.removeGroupItems(context);
    var items: Record<string, string> = {};

    switch (type) {
      case 'C':
        items = context._relationships.credentials;
        break;
      case 'E':
        items = context._relationships.environmentVariables;
        break;
      case 'O':
        items = context._relationships.objects;
        break;
      case 'P':
        items = context._relationships.processes;
        break;
      case 'R':
        items = context._relationships.releases;
        break;
    }

    var yOffset: number = 10;

    Object.keys(items).forEach((key) => {
      var value = items[key];
      var item = this._graphSvc.addItem(context._svg, value, type, 515, yOffset);
      var path = this._graphSvc.addPath(context._svg, { x: 475, y: 20 }, { x: 515, y: yOffset + 10 });
      yOffset += 30;
      context._itemArray.push(item);
      context._itemArray.push(path);
    });
  }

  public removeGroupItems(context: RelationshipViewComponent): void {
    context._itemArray.forEach((item) => {
      item.remove();
    });
    context._itemArray = new Array<SVGElement>();
  }
}
