<div class="page-grid">
    <header>
        <app-page-header [hasShadow]="false">Dashboard</app-page-header>
    </header>
    <nav>
    </nav>
    <main>
        <!-- <app-relationship-view></app-relationship-view> -->
        <!-- <app-relationship-view></app-relationship-view> -->
    </main>
    <nav class="page-actions">
        <ul class="navbar-nav">
            <li class="nav-title">Actions</li>
            <li class="nav-item d-flex flex-row flex-grow-1 justify-content-between">
                <a href="#" class="nav-link" data-bs-toggle="modal" data-bs-target="#createAutomationModal">Create Automation</a>
                <a #createAutomationHelp href="#" class="nav-item-icon" data-bs-toggle="tooltip" data-bs-title="placeholder" data-bs-html="true">
                    <fa-icon [icon]="faCircleQuestion"></fa-icon>
                </a>
            </li>
        </ul>
    </nav>
</div>

<!-- #region Automation Modal -->
<form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div #automationModal class="modal fade" id="createAutomationModal" tabindex="-1" aria-labelledby="createAutomationModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h1 class="modal-title fs-5" id="createAutomationModalLabel">Create Automation Fact Sheet</h1>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col">
                            <div class="alert alert-light" role="alert" [innerHTML]="createAutomationHelpText"></div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <!-- #region Search -->
                            <div class="mb-3">
                                <div class="d-flex flex-row flex-grow-1 justify-content-between">
                                    <label for="searchInput" class="form-label">Search</label>
                                    <a #searchHelp href="#" class="nav-item-icon" data-bs-toggle="tooltip" data-bs-title="placeholder" data-bs-html="true">
                                        <fa-icon [icon]="faCircleQuestion"></fa-icon>
                                    </a>
                                </div>
                                <input type="text" class="form-control" id="searchInput" placeholder="Search..." formControlName="search">
                                <div *ngIf="noContentResult" class="form-text text-danger">No matching projects found.</div>
                            </div>
                            <!-- #endregion -->
                            <!-- #region Projects -->
                            <div class="mb-3">
                                <div class="d-flex flex-row flex-grow-1 justify-content-between">
                                    <label for="searchResults" class="form-label"> Projects</label>
                                    <a #projectHelp href="#" class="nav-item-icon" data-bs-toggle="tooltip" data-bs-title="placeholder" data-bs-html="true">
                                        <fa-icon [icon]="faCircleQuestion"></fa-icon>
                                    </a>
                                </div>
                                <div class="position-relative">
                                    <select class="form-select" id="searchResults" size="4" formControlName="projectId">
                                        <option *ngFor="let item of searchResults" [value]="item.key">
                                            {{item.value}}
                                        </option>
                                    </select>
                                    <ngx-spinner [fullScreen]="false" [name]="searchResultSpinner" type="ball-8bits" size="default"></ngx-spinner>
                                </div>
                            </div>
                            <!-- #endregion -->
                            <!-- #region Details -->
                            <div class="mb-3">
                                <div class="d-flex flex-row flex-grow-1 justify-content-between">
                                    <label for="searchResults" class="form-label">Automation Details</label>
                                    <a #detailsHelp href="#" class="nav-item-icon" data-bs-toggle="tooltip" data-bs-title="placeholder" data-bs-html="true">
                                        <fa-icon [icon]="faCircleQuestion"></fa-icon>
                                    </a>
                                </div>
                            </div>
                            <div class="mb-3">
                                <label for="name" class="form-label">Name</label>
                                <input type="email" class="form-control" id="name" formControlName="name">
                            </div>
                            <div class="mb-3">
                                <label for="description" class="form-label">Description</label>
                                <textarea class="form-control" id="description" rows="3" formControlName="description"></textarea>
                            </div>
                            <!-- #endregion -->
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="submit" class="btn btn-primary" [disabled]="form.invalid">Create Automation</button>
                    <button type="button" class="btn btn-outline-primary" data-bs-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </div>
</form>
<!-- #endregion-->