import { Injectable } from '@angular/core';
import { SvgService } from './svg.service';

@Injectable({
  providedIn: 'root'
})
export class GraphService {

  constructor(private _svgSvc: SvgService) { }

  public createSvg(width: string = '100%', height: string = '200px'): SVGElement {
    const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
    svg.setAttribute('width', width);
    svg.setAttribute('height', height);
    svg.setAttribute('xmlns', 'http://www.w3.org/2000/svg');
    return svg;
  }

  public addItem(parent: SVGElement, name: string, type: 'C' | 'E' | 'O' | 'P' | 'R', x: number, y: number): SVGElement {
    var style = this.getStyle(type);

    var itemGroup = this._svgSvc.createGroup(`${x}`, `${y}`);
    itemGroup.classList.add('item');

    var iconGroup = this._svgSvc.createGroup('0', '0');
    var iconRect = this._svgSvc.createRect(style);
    var iconText = this._svgSvc.createText(type.toString(), 10, 12, 'icon');
    iconGroup.appendChild(iconRect);
    iconGroup.appendChild(iconText);
    itemGroup.appendChild(iconGroup);

    var labelText = this._svgSvc.createText(name, 30, 15);
    itemGroup.appendChild(labelText);

    parent.appendChild(itemGroup);
    return itemGroup;
  }

  public addGroup(parent: SVGElement, name: string, type: 'C' | 'E' | 'O' | 'P' | 'R', x: number, y: number): SVGElement {
    var style = this.getStyle(type);

    var anchor = document.createElementNS('http://www.w3.org/2000/svg', 'a');

    var group = this._svgSvc.createGroup(`${x}`, `${y}`);
    group.classList.add('group');

    var rect = this._svgSvc.createRect(style);
    var text = this._svgSvc.createText(name, 10, 15);
    anchor.appendChild(rect);
    anchor.appendChild(text);
    group.appendChild(anchor);

    parent.appendChild(group);
    return group;
  }

  public addPath(parent: SVGElement, from: { x: number, y: number }, to: { x: number, y: number }): SVGElement {
    var path = document.createElementNS('http://www.w3.org/2000/svg', 'path');

    var xMidppint = (from.x + to.x) / 2;

    var m = `M${from.x},${from.y}`;
    var c = `C${xMidppint},${from.y} ${xMidppint},${to.y} ${to.x},${to.y}`;

    path.setAttribute('d', `${m} ${c}`);
    parent.appendChild(path);
    return path;
  }

  private getStyle(type: 'C' | 'E' | 'O' | 'P' | 'R'): string {
    var style: string;

    switch (type) {
      case 'C':
        style = 'credential';
        break;
      case 'E':
        style = 'envvar';
        break;
      case 'O':
        style = 'object';
        break;
      case 'P':
        style = 'process';
        break;
      case 'R':
        style = 'release';
        break;
    }

    return style;
  }
}
