import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment.local';
import { SearchRequest } from '../requests/search-request';
import { Observable } from 'rxjs';
import { SearchResponse } from '../responses/search-response';

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  private _bluePrismUrl: string;
  private _projectUrl: string;
  private _devOpsUrl: string;

  constructor(private _client: HttpClient) {
    this._bluePrismUrl = environment.bluePrismUrl;
    this._projectUrl = environment.projectUrl;
    this._devOpsUrl = environment.devOpsUrl;
  }

  public searchProjects(request: SearchRequest): Observable<SearchResponse> {
    let params = new HttpParams()
    params = params.set('phrase', request.phrase);

    return this._client.get<SearchResponse>(this._projectUrl + '/search', { params: params });
  }

  public searchAutomations(request: SearchRequest): Observable<SearchResponse> {
    let params = new HttpParams()
    params = params.set('phrase', request.phrase);

    return this._client.get<SearchResponse>(this._devOpsUrl + '/search', { params: params });
  }

  public searchBluePrism(request: SearchRequest): Observable<SearchResponse> {
    let params = new HttpParams()
    params = params.set('phrase', request.phrase);

    return this._client.get<SearchResponse>(this._bluePrismUrl + '/search', { params: params });
  }
}
