import { Component } from '@angular/core';
import { PageHeaderComponent } from '../../components/page-header/page-header.component';
import { RelationshipViewComponent } from '../../components/relationship-view/relationship-view.component';
import { AutomationNavigationComponent } from '../../components/automation-navigation/automation-navigation.component';

@Component({
  selector: 'app-automation-code-review',
  standalone: true,
  imports: [PageHeaderComponent, RelationshipViewComponent, AutomationNavigationComponent],
  templateUrl: './automation-code-review.page.html',
  styleUrl: './automation-code-review.page.scss'
})
export class AutomationCodeReviewPage {

}
