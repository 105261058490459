import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment.local';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GetReleaseResponse } from '../responses/get-release-response';

/**
 * Service for interacting with Blue Prism Microservice
 */
@Injectable({
  providedIn: 'root'
})
export class BluePrismService {

  private _bluePrismUrl: string;

  constructor(private _client: HttpClient) {
    this._bluePrismUrl = environment.bluePrismUrl;
  }

  /**
   * Get a release by its ID
   * @param releaseId The ID of the release to get
   */
  public getRelease(releaseId: number): Observable<GetReleaseResponse> {
    return this._client.get<GetReleaseResponse>(`${this._bluePrismUrl}/release/${releaseId}`);
  }
}
