import { Routes } from '@angular/router';
import { RootLayout } from './layouts/root/root.layout';
import { DashboardPage } from './pages/dashboard/dashboard.page';
import { AutomationFactSheetPage } from './pages/automation-fact-sheet/automation-fact-sheet.page';
import { AutomationCodeReviewPage } from './pages/automation-code-review/automation-code-review.page';

export const routes: Routes = [
    {
        path: '',
        component: RootLayout,
        children: [
            { path: '', component: DashboardPage },
            {
                path: 'automation/:id', children: [
                    { path: 'fact-sheet', component: AutomationFactSheetPage },
                    { path: 'code-review', component: AutomationCodeReviewPage },
                ]
            }
        ]
    }
];
