import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { PageHeaderComponent } from '../../components/page-header/page-header.component';
import { RelationshipViewComponent } from "../../components/relationship-view/relationship-view.component";
import { AutomationNavigationComponent } from "../../components/automation-navigation/automation-navigation.component";
import { FaIconComponent } from '@fortawesome/angular-fontawesome'
import { faCircleQuestion } from '@fortawesome/free-regular-svg-icons';
import { Modal, Tooltip } from 'bootstrap';
import { debounceTime, Observer, of, Subject, switchMap } from 'rxjs';
import { SearchRequest } from '../../requests/search-request';
import { SearchService } from '../../services/search.service';
import { SearchResult } from '../../responses/search-response';
import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { NgxSpinnerModule, NgxSpinnerService } from 'ngx-spinner';
import { FormArray, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { BluePrismService } from '../../services/blue-prism.service';
import { ReleaseEntry } from '../../responses/get-release-response';
import { AutomationService } from '../../services/automation.service';
import { ActivatedRoute } from '@angular/router';
import { GetAutomationGeneralInformationResponse } from '../../responses/get-automation-general-information-response';
import { ComponentIconComponent } from "../../components/component-icon/component-icon.component";
import { AddComponentsCommand } from '../../requests/add-components-command';
import { ToastComponent } from "../../bootstrap/toast/toast.component";

@Component({
  selector: 'app-automation-fact-sheet',
  standalone: true,
  imports: [
    NgFor,
    NgIf,
    AsyncPipe,
    ReactiveFormsModule,
    FaIconComponent,
    NgxSpinnerModule,
    PageHeaderComponent,
    RelationshipViewComponent,
    AutomationNavigationComponent,
    ComponentIconComponent,
    ToastComponent
  ],
  templateUrl: './automation-fact-sheet.page.html',
  styleUrl: './automation-fact-sheet.page.scss'
})
export class AutomationFactSheetPage implements AfterViewInit {

  @ViewChild('addItemHelp') addItemHelp!: ElementRef;
  public addItemHelpText = 'Please add the relevant <span class="fw-bold text-release">Release Package</span> to fill the Fact Sheet with life.';

  @ViewChild('searchHelp') searchHelp!: ElementRef;
  public searchHelpText = 'Enter a search term to find <span class="fw-bold text-release">Release Packages</span>.';

  @ViewChild('releaseHelp') releaseHelp!: ElementRef;
  public releaseHelpText = 'Select one or more <span class="fw-bold text-release">Release Packages</span> to list the components associated with them.';

  @ViewChild('componentHelp') componentHelp!: ElementRef;
  public componentHelpText = 'Select one or more <span class="fw-bold text-release">Components</span> to add to the Fact Sheet.';

  @ViewChild('releaseModal') releaseModal!: ElementRef;
  @ViewChild('toast') toast!: ToastComponent;
  @ViewChild('submitButton') submitButton!: ElementRef;

  public searchResults: SearchResult[] = [];
  public releaseEntries: ReleaseEntry[] = [];
  public faCircleQuestion = faCircleQuestion;
  public searchResultSpinner: string = 'searchResultSpinner';
  public releasehResultSpinner: string = 'releaseResultSpinner';
  public form: FormGroup = new FormGroup({});
  public noContentResult: boolean = false;
  public addedComponentsCount: number = 0;

  public generalInfo$: Subject<GetAutomationGeneralInformationResponse> = new Subject();

  get search() { return this.form.get('search') as FormControl; }
  get releases() { return this.form.get('releases') as FormArray; }
  get components() { return this.form.get('components') as FormArray; }

  private _automationId!: string;

  constructor(
    private _route: ActivatedRoute,
    private _searchSvc: SearchService,
    private _bluePrismSvc: BluePrismService,
    private _automationSvc: AutomationService,
    private _spinnerSvc: NgxSpinnerService
  ) {
    this.form = new FormGroup({
      search: new FormControl('', [Validators.required]),
      releases: new FormControl({ value: '', disabled: true }, [Validators.required]),
      components: new FormControl({ value: '', disabled: true }, [Validators.required])
    });

    this._route.paramMap
      .pipe(switchMap((params) => { return of(params.get('id') as string); }))
      .subscribe((automationId) => {
        this._automationId = automationId;
      });
  }

  public ngAfterViewInit(): void {
    this.initTooltips();
    this.initFormEvents();
    this.initModal();
    this.initFactSheet();
  }

  /**
   * Triggered when the form is submitted
   * Adds the selected components to the automation fact and displays a toast message
   * Hides the modal and removes the backdrop
   */
  public onSubmit(): void {
    var components: { [key: string]: string; } = {};
    this.components.value.forEach((item: number) => {
      const type = this.releaseEntries.find(entry => entry.id === item)?.typeKey;
      if (type === undefined) return;
      components[item] = type;
    });

    var request = { automationId: this._automationId, components: components } as AddComponentsCommand;
    this._automationSvc.addComponents(request).subscribe({
      next: () => {
        this.toast.showToast();
        Modal.getOrCreateInstance(this.releaseModal.nativeElement).hide();
        this.addedComponentsCount = this.components.value?.length;
        var backdrops = document.getElementsByClassName('modal-backdrop');
        for (var i = 0; i < backdrops.length; i++) {
          backdrops[i].remove();
        }
      }
    });
  }

  /**
   * Initialize the modal
   * Creates the modal instance and sets the event listener for the 'hidden' event
   */
  private initModal() {
    this.releaseModal.nativeElement.addEventListener('hidden.bs.modal', () => {
      console.log('Modal hidden');
      this.form.reset();
      this.searchResults = [];
      this.releaseEntries = [];
      this.releases?.disable();
      this.components?.disable();
    });
  }

  /**
   * Initialize the tooltips
   * Creates the tooltips for the help icons and sets the content 
   * of the tooltips to the help text
   */
  private initTooltips(): void {
    var addItemTooltip = Tooltip.getOrCreateInstance(this.addItemHelp.nativeElement);
    addItemTooltip?.setContent({ '.tooltip-inner': this.addItemHelpText });

    var searchTooltip = Tooltip.getOrCreateInstance(this.searchHelp.nativeElement);
    searchTooltip?.setContent({ '.tooltip-inner': this.searchHelpText });

    var releaseTooltip = Tooltip.getOrCreateInstance(this.releaseHelp.nativeElement);
    releaseTooltip?.setContent({ '.tooltip-inner': this.releaseHelpText });

    var componentTooltip = Tooltip.getOrCreateInstance(this.componentHelp.nativeElement);
    componentTooltip?.setContent({ '.tooltip-inner': this.componentHelpText });
  }

  /**
   * Initialize the form events
   * Subscribes to the value changes of the form controls
   * and triggers the appropriate actions based on the value changes
   */
  private initFormEvents(): void {
    this.search?.valueChanges
      .pipe(debounceTime(500))
      .subscribe(this.searchValueChanges());

    this.releases?.valueChanges
      .subscribe(this.releaseValueChanges());

    this.components?.valueChanges
      .subscribe(this.componentsValueChanges());
  }

  /**
   * Initialize the Fact Sheet
   * Fetches the general information for the automation
   */
  private initFactSheet(): void {
    this._automationSvc.getGeneralInformation({ automationId: this._automationId })
      .subscribe(this.getGeneralInformationObserver());
  }

  /**
   * Triggered when the value of the search form control changes
   * Searches for Blue Prism releases based on the search term
   */
  private searchValueChanges(): Partial<Observer<any>> {
    return {
      next: value => {
        if (value === null || value === '') return;
        if (value.length < 3) return;
        this._spinnerSvc.show(this.searchResultSpinner);
        this.onSearch(value);
      }
    }
  }

  /**
   * Triggered when the value of the releases form control changes
   * Loads the release information for the selected release
   */
  private releaseValueChanges(): Partial<Observer<any>> {
    return {
      next: value => {
        if (value === null || value === '') return;
        this._spinnerSvc.show(this.releasehResultSpinner);
        this.onLoadRelease(value);
      }
    }
  }

  /**
   * Search for Blue Prism releases based on the search term
   * @param value The search term
   */
  private onSearch(value: string): void {
    var request = { phrase: value } as SearchRequest;
    this._searchSvc.searchBluePrism(request).subscribe({
      next: response => {
        if (response === null || response.results === null || response.results.length === 0) {
          this.noContentResult = true;
          this._spinnerSvc.hide(this.searchResultSpinner);
          return;
        }
        this.searchResults = response.results;
        this._spinnerSvc.hide(this.searchResultSpinner);
        this.releases?.enable();
      }
    });
  }

  /**
   * Load the release information for the selected release
   * @param value The ID of the selected release
   */
  private onLoadRelease(value: number): void {
    this._bluePrismSvc.getRelease(value).subscribe({
      next: response => {
        this.releaseEntries = response.entries
          .filter(entry => !['process-group', 'object-group', 'schedule'].includes(entry.typeKey))
          .sort();
        this._spinnerSvc.hide(this.releasehResultSpinner);
        this.components?.enable();
      }
    });
  }

  /**
   * Handle the response from the get general information request
   */
  private getGeneralInformationObserver(): Partial<Observer<any>> {
    return {
      next: (generalInfo) => {
        this.generalInfo$.next(generalInfo);
      }
    } as Observer<GetAutomationGeneralInformationResponse>;
  }

  /**
   * Triggered when the value of the components form control changes
   * Updates the text of the submit button to reflect the number of components selected
   */
  private componentsValueChanges(): Partial<Observer<any>> {
    return {
      next: value => {
        if (value === null || value === '') return;
        (this.submitButton.nativeElement as HTMLButtonElement).textContent = `Add ${value.length} Components`;
      }
    }
  }
}
