<div class="fact-sheet-grid">
    <header>
        <app-page-header [hasShadow]="false">Dashboard</app-page-header>
    </header>
    <nav>
        <app-automation-navigation></app-automation-navigation>
    </nav>
    <main>
        
    </main>
    <nav>
    </nav>
</div>